import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import reportReducer from './reducers/report';
import resultReducer from './reducers/result';
import appReducer from './reducers/app';

const rootReducer = combineReducers({
	app: appReducer,
	report: reportReducer,
	result: resultReducer
});

// export default applyMiddleware(thunk)(createStore)(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export default applyMiddleware(thunk)(createStore)(rootReducer);