import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import rootStore from './store';
import registerServiceWorker from './registerServiceWorker';
import Loading from './components/loading/Loading';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';

library.add(fas);
const App = React.lazy(() => import('./App'));

ReactDOM.render(
	<Provider store={rootStore}>
		<HashRouter>
			<Suspense fallback={Loading}>
				<PrivateRoute path="/" component={App} />
			</Suspense>
		</HashRouter>
	</Provider>
, document.getElementById('root'));

registerServiceWorker();