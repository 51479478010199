import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../login/Login';

class PrivateRoute extends React.Component {

	render() {
		const { component: Component, ...rest } = this.props;

		return (
			<Route 
				{...rest}
				render={props =>
					localStorage.getItem('key') ? (
						<Component {...this.props} />
					) : (
						<Login {...this.props} />
					)
				}
			/>
		);
	}
}

export default PrivateRoute;