const INITIAL_STATE = {
	bases: {},
	processo: '',
	data: '',
	hash: '',
};

export default(state = INITIAL_STATE, action) => {
	const bases = {...state.bases};
	switch (action.type) {
		case 'REPORT_CHANGE':
			return {...state, ...action.payload};
		case 'REPORT_ADD':
			if (!bases.hasOwnProperty(action.payload.name)) {
				bases[action.payload.name] = [];
			}

			bases[action.payload.name].push(action.payload.data);

			return {...state, bases};
		case 'REPORT_REMOVE':
			bases[action.payload.name] = bases[action.payload.name].filter(reg => {
				return !(JSON.stringify(reg) === JSON.stringify(action.payload.data))
			});

			if (!bases[action.payload.name].length) {
				delete bases[action.payload.name];
			}

			return {...state, bases};
		case 'REPORT_SET':
			return { bases: action.payload };
		case 'APP_CHANGE_CUSTOM_TYPE':
		case 'REPORT_CLEAR':
			return INITIAL_STATE;
		default:
			return state;
	}
};