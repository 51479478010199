const INITIAL_STATE = {
	result: {}
};

export default(state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'RESULT_ADD':
			return { result: {...state.result, ...action.payload} };
		case 'APP_CHANGE_CUSTOM_TYPE':
		case 'RESULT_CLEAR':
			return INITIAL_STATE;
		default:
			return state;
	}
};